import React from 'react'
import { Col, Image } from 'react-bootstrap'

const Social_media = () => {
  return (
    <section>
              <Col>
        <Image src="/Icons/facebook.png"  className='Social_icons  align-items-center'/> 
            </Col>

            <Col>
        <Image src="/Icons/instagram.png"  className='Social_icons align-items-center'/>
            </Col>

            <Col>
        <Image src="/Icons/tik-tok.png"  className='Social_icons  align-items-center'/>
            </Col>

            <Col>
        <Image src="/Icons/twitter.png"  className='Social_icons  align-items-center'/>
            </Col>

            <Col>
        <Image src="/Icons/snapchat.png"  className='Social_icons  align-items-center'/>
            </Col>
      
            <Col>
        <Image src="/Icons/linkedin.png"  className='Social_icons  align-items-center'/>
            </Col>
    </section>
  )
}

export default Social_media
