import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Col, Image, Row } from 'react-bootstrap'
import { Avatar, Card, CardHeader, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { blue, red, teal } from '@mui/material/colors';
import { Link,useParams } from 'react-router-dom';
import ProjectsDetilsHook from '../../Custom_Hook/Project/ProjectsDetilsHook';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const ProjectsDetils = () => {




const {id}=useParams();
const [item]= ProjectsDetilsHook(id)

  return (
    <Card shadow="md" radius="md"  >
   <Row className='justify-content-center '>

   <Col  xs={12} md={8} lg={8}>
          <Image src={'https://api.almenwaleng.hawasib.net/storage/app/'+item.imagepath} className='img_detils' />
        </Col>

        <Col item xs={12} md={4} lg={4}>
        <CardHeader
        title={item.projectname}
        subheader="سمبتمبر 14, 2016"
      />
        <List >
      <ListItem  className='ListItem'>
      <ListItemAvatar>
      <Avatar alt="Cindy Baker"  src="/Icons/bulding.png" />
      </ListItemAvatar>
        <ListItemText
          primary="اسم المشروع"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
             
              >
{item.projectname}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem  className='ListItem'>
        <ListItemAvatar>
        <Avatar alt="Cindy Baker"  src="/Icons/employees.png" />
        </ListItemAvatar>
        <ListItemText
          primary="الجهة المالكة"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
               
              >
               {item.destname}
              </Typography>
         
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem className='ListItem'>
        <ListItemAvatar>
        <Avatar alt="Cindy Baker"  src="/Icons/location2.png" />
        </ListItemAvatar>
        <ListItemText
          primary="موقع المشروع"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
            {item.location}
              </Typography>
            
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" />
      <ListItem className='ListItem'>
        <ListItemAvatar>
        <Avatar alt="Cindy Baker"  src="/Icons/project.png" />
        </ListItemAvatar>
        <ListItemText
          primary="وصف المشروع"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display:'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              {item.projectdesc}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

    </List>
    <Link to="/"  target="_blank" style={{ textDecoration: 'none' }}>
    <Button variant="contained" className='btn-save d-inline mt-2'>
     رجوع
   </Button>
   </Link>
        </Col>

  
</Row>
</Card>
  )
}

export default ProjectsDetils

