// import { Button, Offcanvas } from 'bootstrap'
import React, { useState } from 'react'
import { Button, Container, Form, Nav, NavDropdown, Navbar, Offcanvas,  } from 'react-bootstrap'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import About_company from '../About_company';
import DesignRequest from '../DesignRequest/DesignRequest';
import Contact from '../contact';
import Gallery from '../Gallery/Gallery';
import ServicesAll from '../servicesAll';
const NavBar = () => {

  return (
    <BrowserRouter>
    <>
    {[false ].map((expand) => (
      <Navbar key={expand} expand={expand} className="light mb-3">
        <Container fluid>
        <Navbar.Brand href="/">
            <img
              alt=""
              src="/Photos/Logol.png"
              // width="40%"
 className="Logo d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle  />
          <Navbar.Offcanvas
            placement="end"
          >
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">الرئيسية</Nav.Link>
                <Nav.Link as={Link} to="/about2" target="_blank" >  من نحن </Nav.Link>
                <Nav.Link  as={Link} to="/services2" target="_blank" >خدماتنا</Nav.Link>
                <Nav.Link as={Link} to="/Gallery2" target="_blank">معرض الصور</Nav.Link>
                <Nav.Link  as={Link} to="/Request2"target="_blank">طلب تصميم</Nav.Link>
                <Nav.Link as={Link} to="/contact2" target="_blank"> تواصل معنا </Nav.Link>
                <Nav.Link  as={Link} to="/login"  target="_blank">تسجل دخول</Nav.Link>
              </Nav>
              <Form className="d-flex">
                <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search" />
                <Button variant="outline-success">بحث</Button>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}
  </>
  <div>
  <Routes>
   <Route path="/about2" element={<About_company/>}/>  
   <Route path="/Request2" element={<DesignRequest/>}/> 
   <Route path="/contact2" element={<Contact/>}/> 
   <Route path="/Gallery2" element={<Gallery/>}/> 
   <Route path="/services2" element={<ServicesAll/>}/> 
   </Routes>
                </div>

                </BrowserRouter>
  )



}

export default NavBar