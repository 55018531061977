import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AdminSideBar from '../../Components/Admin/AdminSideBar'
import AdminAllProjects from '../../Components/Admin/AdminAllProjects'
import Pagination from '../../Components/Uitily/Pagination'
import { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { getAllProject } from '../../redux/Actions/ProjectAction';
import { getAllProjectpage } from '../../redux/Actions/ProjectAction';

const AdminAllProjectsPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAllProject(6));
    }, [])
  const Project= useSelector(state=> state.allProjects.Project)
  const loading = useSelector(state=> state.allProjects.loading)
  
   //to get page count
   let pageCount=0; 
   try {
    if(Project.paginationResult)
    pageCount=Project.paginationResult.numberofpages
  else
  pageCount=[]
   } catch (error) { }
 
  
      //when press pagintion
      const getPage =(page)=>{
        dispatch(getAllProjectpage(page));
        // console.log(page)
      }
    return (
        <Container >
            <Row className='py-3'>
                <Col sm="3" xs="2" md="2">
                    <AdminSideBar />
                </Col>

                <Col sm="9" xs="10" md="10">
                    <AdminAllProjects data={Project.data} loading={loading}/>
                    <Pagination onPress={getPage}  pageCount={pageCount}/>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminAllProjectsPage
