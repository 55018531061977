import React from 'react'
import {  Button} from '@mui/material'
import { styled } from '@mui/material/styles';
import { Col, Image, Row } from 'react-bootstrap'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Social_media from './Social_media';
import Menu from './Menu';
import { Fade } from 'react-reveal';
import LightSpeed from 'react-reveal/LightSpeed';



const About_company = () => {
 
  return (

<section className='about'>
     
      <Row className='justify-content-center '>
      <div className='titel'>من نحن </div>
      <Col  xs={1} md={1} lg={1} className='align-items-center'>
      <div className='media_abu'>
        <Social_media/>
        </div> 
       </Col>
                 <Col xs={12} md={4} lg={5} className='d-flex align-items-center p-3 '>
              
                 <Image src="/Photos/about_img.png"  className='about_img' rounded/>
                    </Col>

                    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
                    <Fade top>
                    <div className='titel_sub'> المنوال للاستشارات الهندسية </div>
  <p className='text_body'>
  تأسس مكتب المنوال للاستشارات الهندسية بشهادة تسجيل مهنية للمهندس فارس الشمري لالستشارات الهندسية ومـــن
هنا اخذ الرمز المختصر الذي اشتهر لاحقا SSCENG  <br></br>
تم تمرير قيم العمل االساسية من فريق عمل لفريق عمل لتحقيق التزام المؤسسين بتوفير دعم حقيقي لمفهــوم البيئــــــــة
الخضراء والمحافظة على قياسية عالية في الحفاظ على البيئة ورفع مستوى جودة حياة االنسان من خالل اعمال التصميم
واالشراف الهندسي والتي ينبغي ان توفر بجودة عالية بافضل االسعار, كل يوم يمضي ندعم قوة وخبرة فرق العمل فـــــي
جميع مواقع العمل ونرفع االنتاجية, نحارب الهدر, نحتفل بالزبون ونضع لالمن الصناعي االولوية, تتطــور فرق العـــــمل
لتحقق فلسفتنا والتي تعتبر نقطة حرجة في مسار نجاحنا. انها ليست فقط ثقافتنا ولكن هي ايضا اســـلوبنا في الحياة وفـي
المنوال لالستشارات الهندسية
  </p>
  <Row className='justify-content-center '>
   <Col>
   <Button variant="contained"  className='btn-grad m-2'>
      <CloudUploadIcon className='m-1'/>   تحميل ملف الشركة 
      </Button>

      <Button variant="contained"  className='btn-grad' >
        المزيد <ExpandMoreIcon className='m-1'/>
      </Button>
   </Col>
   
</Row>

</Fade>

       </Col>
                   
        <Col  xs={1} md={1} lg={1} className='justify-content-center align-items-center'>
        <div className='menu_abu'>
               <Menu/>
               </div>
  </Col>

        </Row>
    
      </section>
     
 
    
  )
}

export default About_company
