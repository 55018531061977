export const GET_ERROR="GET_ERROR"

export const GET_ALL_PROJECT="GET_ALL_PROJECT"
export const GET_PROJECT_DETALIS="GET_PROJECT_DETALIS"
export const CREATE_PROJECT="CREATE_PROJECT"


export const GET_ALL_REQUEST="GET_ALL_PROJECT"
export const GET_ONE_REQUEST="GET_ONE_PROJECT"
export const CREATE_REQUEST="CREATE_PROJECT"
export const UPDATE_PROJECT="UPDATE_PROJECT"
export const DELETE_PROJECT="DELETE_PROJECT"


export const GET_ALL_Images="GET_ALL_Images"
export const  CREATE_GALLERY=" CREATE_GALLERY"
export const  GET_ALL_Images2=" GET_ALL_Images2"
export const  GET_ALL_Images3="GET_ALL_Images3"

export const CREATE_NEW_USER="CREATE_NEW_USER"
export const LOGIN_USER="LOGIN_USER"