import React from 'react'
import { Row,Col } from 'react-bootstrap'
import CartItem from '../Cart/CartItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarDays,faUser,faCompassDrafting,faLocationPin,faMapLocation,faFileLines,faPhone} from '@fortawesome/free-solid-svg-icons'
const AdminOrderDetalis = () => {
    return (
        <div className="form-row">
        <div className="form-group row col-4 mt-3">
        <div className="col-md-12">    
        <p className="text-gy"> رقم الطلب</p>
         <p > 0004 </p>
         <p className="text-gy"> تاريخ الطلب</p>
         <p > <FontAwesomeIcon icon={faCalendarDays} />  الميلادي : 1/2/2023    الموافق: 12/8/1446  </p>
        </div>
        </div>
        <div className="form-group row col-12 mt-3">
        <div className="col-md-4">    
         <p className="text-gy">اسم العميل</p>
          <p> <FontAwesomeIcon icon={faUser} />محمد احمد ادم	</p>
        </div>
        <div className="col-md-4">    
        <p className="text-gy"> نوع الطلب</p>
         <p> <FontAwesomeIcon icon={faCompassDrafting} /> شركة </p>
        </div>
        
        <div className="col-md-4">    
         <p className="text-gy">الموقع</p>
          <p> <FontAwesomeIcon icon={faLocationPin} /> التقاضي ,لوائح تنظيم العمل </p>
        </div>
        
        </div>
        
        
        
        <div className="form-group row col-12 mt-3">
        <div className="col-md-4">    
        <p className="text-gy"> العنوان</p>
         <p> <FontAwesomeIcon icon={faMapLocation} /> 1/3/2022 </p>
        </div>
        
        <div className="col-md-4">
        <p className="text-gy">رقم التواصل</p>
          <p > <FontAwesomeIcon icon={faPhone} />  000000000 </p>
        </div>
      
        </div>
        
        
        <div className="form-group row col-12 mt-3">
        <div className="col-md-10">    
         <p className="text-gy" >الوصف</p>
          <p >    <FontAwesomeIcon icon={faFileLines} />   
          هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا 
          النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا
           النص أو العديد من 
           النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
        </p>
        </div>
        
        
        </div>
        
        </div>
    )
}

export default AdminOrderDetalis
