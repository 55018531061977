import React from 'react'
import avatar from '../../images/avatar.png'
import { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { createProject } from '../../redux/Actions/ProjectAction';
import { useEffect } from 'react';
import notify from '../../Custom_Hook/UseNotifaction';

const AddProjectHook = () => {

    const dispatch = useDispatch();
    const [img, setImg] = useState(avatar)
    const [selectedFile, setSelectedFile] = useState(null)
    const [name, setName] = useState('')
    const [destname, setDestName] = useState('')
    const [location, setLocation] = useState('')
    const [projectdesc, setprojectdesc] = useState('')
    const [projectdate, setProjectdate] = useState('')
    const [loading,setLoading] = useState(true)
    const [isPress,seIsPress] = useState(false)



//to change name state
const onChangeName=(e)=>{
    e.persist();
    setName(e.target.value)
}

const onChangeDestName=(e)=>{
    e.persist();
    setDestName(e.target.value)
}

const onChangeLocation=(e)=>{
    e.persist();
    setLocation(e.target.value)
}


const onChangeProjectdate=(e)=>{
    e.persist();
    setProjectdate(e.target.value)
}


const onChangeprojectdesc=(e)=>{
    e.persist();
    setprojectdesc(e.target.value)
}






    //when image change save it
    const onImageChange=(e)=>{
        if(e.target.files && e.target.files[0]){
            setImg(URL.createObjectURL(e.target.files[0]))
            //حفظ مسار الملف لتخزين
            setSelectedFile(e.target.files[0])
        }
            }

            const res= useSelector(state=> state.allProjects.Project)
          
            const AddProject = async(e)=>{
                e.preventDefault();
                if(name===""|| selectedFile=== null || destname==="" || location==="" || projectdesc===""  || projectdate==="" ){
                    // console.log('من فضلك اكل البايانات')
                   // <h4> من فضلك اكل البايانات</h4>
                   notify(" من فضلك اكل البايانات  ","warn");
                   return;
               }
                const formData =new FormData();
                formData.append("projectname",name)
                formData.append("imagepath",selectedFile)
                formData.append("destname",destname)
                formData.append("location",location)
                formData.append("projectdesc",projectdesc)
                formData.append("projectdate",projectdate)
                if(loading)
                setLoading(true)
                seIsPress(true)
                console.log("جاري التحميل")
                await dispatch(createProject(formData)) 
                setLoading(false)
            }
            useEffect(() => {
                if(loading === false) {
                setImg(avatar)
                setName("")
                setSelectedFile(null)
                setDestName("")
                setLocation("")
                setprojectdesc(" ")
                setProjectdate(" ")
                console.log('تم الانتهاء')
              setLoading(true)
              seIsPress(false)
              setTimeout(()=>seIsPress(false),1200)
              if(res.status === 200){
                 notify("تم اضافة المشروع بنجاح بنجاح","success");
              }else{
                 notify(" هناك مشكلة في عملية الاضافة ","error");
              }
              
            if(res)
            console.log(res.status)

                }
             }, [loading])


             return[img,name,projectdate,destname,location,projectdesc,loading,isPress,onImageChange,onChangeName,onChangeDestName,onChangeLocation,onChangeProjectdate,onChangeprojectdesc,AddProject]


}

export default AddProjectHook
