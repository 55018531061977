import { GET_ALL_PROJECT,GET_ERROR,CREATE_PROJECT,GET_PROJECT_DETALIS,UPDATE_PROJECT,DELETE_PROJECT} from '../Type'
const inital ={
  Project :[],
  ProjectDetalis:[],
  UpdateProject:[],
  deleteProject:[],
    loading : true,
}
const ProjectReduce  = (state= inital, action) => {
switch (action.type) {

    case GET_ALL_PROJECT:
      return{
        ...state,
        Project:action.payload,
       loading:false,
      }

      case GET_PROJECT_DETALIS:
      return{
        ProjectDetalis:action.payload,
       loading:false,
      }

case CREATE_PROJECT:
  return{
    Project:action.payload,
    loading:false,
  }

  case UPDATE_PROJECT:
    return{
      ...state,
      UpdateProject:action.payload,
      loading:false,
    }

    case DELETE_PROJECT:
      return{
        ...state,
        deleteProject:action.payload,
        loading:false,
      }

      case GET_ERROR: return {
        Project:action.payload,
        loading:true,
      }

    default:
return state;
}
}

export default ProjectReduce 
