import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBuildingWheat,faBrush,faCompassDrafting,faBuilding,faRecycle,faAtom,faRulerCombined,faHouseFire} from '@fortawesome/free-solid-svg-icons'
import { Grid } from '@mui/material';
import Fade from 'react-reveal/Fade';
export default function Services() {
  // const [value, setValue] = React.useState('0');

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (







    <Box>
 
      <TabContext value={value} >
      
 
          <TabList onChange={handleChange} variant="scrollable"  scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example" >
            <Tab icon={  <FontAwesomeIcon icon={faBuildingWheat} size="xl" /> } iconPosition="top" label="إدارة المشاريع" value="1" />
            <Tab   icon={  <FontAwesomeIcon icon={faBrush} size="xl" /> }     label="التصميم الداخلي والديكور" value="2" />
            <Tab  icon={  <FontAwesomeIcon icon={faCompassDrafting} size="xl" /> }  label="التصميم الخارجي" value="3" />
            <Tab  icon={  <FontAwesomeIcon icon={faBuilding} size="xl" /> }  label=" التخطيط العمراني" value="4" />
            <Tab  icon={  <FontAwesomeIcon icon={faRecycle} size="xl" /> }  label=" عملية الهندسة الصناعية" value="5" />
            <Tab  icon={  <FontAwesomeIcon icon={faAtom} size="xl" /> }  label=" الدراسات الهيدرولوجية" value="6" />
            <Tab  icon={  <FontAwesomeIcon icon={faRulerCombined} size="xl" /> }  label=" الاشراف الهندسي" value="7" />
            <Tab  icon={  <FontAwesomeIcon icon={faHouseFire} size="xl" /> }  label="السلامة والاطفاء" value="8" />
          </TabList>
         
      
       
        <TabPanel value="1">
        <Fade bottom>
 <p>
  فريق ادارة مشاريع محترف من جميع التخصصات الهندسية يعمل وفق قياسية PMI لإدارة المشاريع التي يتم تكليفنا بها من قبل العملاء وفق عقود ادارة مشاريع في جميع مناطق المملكة العربية السعودية فريقنا جاهزة لإدارة مشاريع الانشاءات و
  التصنيع والخدمات الهندسية
 </p>
 </Fade>
        </TabPanel>
        <TabPanel value="2">
        <Fade top>
<p>
  فريق عمل من مهندسات ومهندسين التصميم الداخلي والديكور لأستخدام افضل البرامج المرخصه وبخبرة كبيرة في مجال الفن التشكيلي الممزوج بالتخصص الهندسي المحترف لتطوير التصاميم الداخلية الراقية ذات الجودة العالية جدا في الاضاءة وتجانس الالون وإختيار الفرش والمواد والتأثبيت حيث يتم  إنتاج لوحات تصميم داخلي ومناظير فائقة الروعة وقابلة للتنفيذ وبأسعار منافسة جدا
</p>
</Fade>
        </TabPanel>
        <TabPanel value="3">
        <Fade bottom>
          <p>
            تصميم معماري وإنشائي وكهربائي وميكانيكي شامل التكييف وصحي وفق كود  البناء السعودي مع إستخراج جميع التراخيص اللازمة من خلال بوابة بلدي والأمانات والبلديات في الرياض وجدة ومكة المكرمة والجوف ورفحاء والمنطقة الشرقية وأبها من خلال فروع مكانبنا اللتي يحتوي كل منها على طاقم عمل هندسي كامل وحساب الكميات والاحمال الانشائية وحساب الاحمال الكهربائية والرفوعات المساحية
          </p>
          </Fade>
        </TabPanel>
        <TabPanel value="4">

        </TabPanel>
      </TabContext>
     
    </Box>
 
  );
}

