import {combineReducers} from 'redux'
import ProjectReduce from'./ProjectReduce'
import DesignRequestReduce from './DesignRequestReduce'
import GalleryReduce from './GalleryReduce'
import AuthReducers from './AuthReducers'
export default combineReducers({
    allProjects:ProjectReduce,
    allDesignRequest:DesignRequestReduce,
    allGallery:GalleryReduce,
    AuthReducers:AuthReducers
    
})