import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import mobile from '../../images/mobile.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
const AdminAllOrdersItem = ({name,type,location,id}) => {
    return (
        <Col sm="12">
           
             
          
                <div className="w-100">
                {/* striped bordered hover */}
                <Table striped>
      <thead className='text-center'>
        <tr>
          <th>رقم الطلب</th>
          <th>الاسم</th>
          <th>نوع التصميم</th>
          <th>موقع المشروع</th>
          <th> الاجراء </th>
        </tr>
      </thead>
      <tbody  className='text-center'>
        <tr>
          <td>{id}</td>
          <td>{name}</td>
          <td>{type}</td>
          <td>{location}</td>
          <td>  
          
             <Button variant="light">
             <Link
                to="/admin/orders/{id}" style={{ textDecoration: "none" }} >
                <FontAwesomeIcon icon={faEye} />
                 </Link>
                </Button> 
             </td>
        </tr>
    
      </tbody>
    </Table>


                </div>
            
        </Col>
    )
}

export default AdminAllOrdersItem
