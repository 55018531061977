import React from 'react'
import VideoPlayer from "react-background-video-player";
import real from '../Video/real.mp4'
const   BgVideo = () => {
  return (
    <div className="bg-video">
    <VideoPlayer
            src={real }
            autoPlay={true}
            muted={true}
            className="bg-video__content"
          />
     
    </div> 
    


  )
}

export default BgVideo