import React, { useEffect } from 'react'
import { motion, useTime, useTransform,useAnimate } from "framer-motion";
import Fade from 'react-reveal/Fade';
import { Col, Image, Row } from 'react-bootstrap'
import CameraEnhanceOutlinedIcon from '@mui/icons-material/CameraEnhanceOutlined';
import {Paper} from '@mui/material'
import { styled } from '@mui/material/styles';
import { useState } from 'react';
// import Paginat from '../Paginat';
import { Link } from 'react-router-dom';


const Projects = ({title,img,item,id}) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      const time = useTime();
      const rotate = useTransform(time, [0, 4000], [0, 360], { clamp: false });
      const control =useAnimate()
    const [mov, setMov] = useState(false)
  return (

       <Col lg={4} md={4}  xs={6} className='d-flex  align-items-center p-3 '>
       <motion.dev
animate={{x:[0,10, 10, 0 ],y:[0, -50, -50,  0], rotate:[0,90,]} }
  transition={{ repeat:Infinity, type:"tween",duration:15,damping:7,control}}>
       <div className='circle_img'>
         <Image src={'https://api.almenwaleng.com/storage/app/'+img}  className='circle_img_image'/>
         <Link   to={`/detils/${id}`}>
         <div className='circle_img_overlay'>
      <div className='overlay_text text-center w-100 p-2'>
      <p className='text-danger'><CameraEnhanceOutlinedIcon/> {title}</p>
      </div>
      </div>
      </Link>
      </div>
      </motion.dev>
        </Col>
  )
}

export default Projects


