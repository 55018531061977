import { Tooltip } from '@mui/material'
import React from 'react'
import { Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Menu = () => {
  return (
    <section  >
                <Col className='d-flex align-items-center'>
                <Tooltip title="الرئيسية" placement="right" arrow >
                <Link to="/" style={{ textDecoration: 'none' }}>
          <Image src="/Photos/minus.png"  className='menu_line'/>
          </Link>
          </Tooltip>
            </Col>
          
            <Col className='d-flex align-items-center'>
            <Tooltip title="من نحن" placement="right" arrow >
            <Link to="/about" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>

            <Col className='d-flex align-items-center  '>
            <Tooltip title="خدماتنا" placement="right" arrow >
            <Link to="/services" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>

            <Col className='d-flex align-items-center ' >
            <Tooltip title="طلب تصميم" placement="right" arrow >
            <Link to="/Request" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>

            <Col className='d-flex align-items-center  '>
            <Tooltip title="مكتبة الصور" placement="right" arrow >
            <Link to="/Gallery" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>

            <Col className='d-flex align-items-center'>
            <Tooltip title="تواصل معنا" placement="right" arrow >
            <Link to="/contact" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>

            <Col className='d-flex align-items-center'>
            <Tooltip title="تسجيل دخول " placement="right" arrow >
            <Link to="/login"  target="_blank" style={{ textDecoration: 'none' }}>
            <Image src="/Photos/minus.png"  className='menu_line'/>
            </Link>
            </Tooltip>
            </Col>
    </section>
  )
}



export default Menu
