import BaseURL from "../Api/BaseURL";

const UseUpdateDataWithImage = async (url,parmas) => {
    const config={
        headrs:{"Content-Type":"multipart/form-data"}
    }
    // const res = await BaseURL.put(url,parmas,config)
    const res = await BaseURL.post(url,parmas,config)
    // console.log(res.status)
    return res;
    }

const UseUpdateData = async (url,parmas) => {
const res = await BaseURL.post(url,parmas)
return res;
}

export {UseUpdateData,UseUpdateDataWithImage}   
