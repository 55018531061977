import React, { useState } from 'react'
import { Col, Image, Row,Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { Box, Button,Grid,Paper, Stack} from '@mui/material'
import DesginRequstHook from '../../Custom_Hook/Requst_Desgin/DesginRequstHook';
import { ToastContainer} from 'react-toastify';
const Request = () => {

const [onChangeName,onChangePhone,onChangeLocation,onChangeAddress,onChangeDesign_type,onChangeEmail,onChangedesc,name,phone,design_type,location,email,desc,address,AddRequest,isPress,loading ] = DesginRequstHook();

  return (
    <>
      
    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
            <Form.Label>الاسم كاملا</Form.Label>
          <Form.Group controlId="formFile" className="mb-3">
           <Form.Control
             onChange={onChangeName}
             value={name}
            type="text" 
            placeholder="" 
            aria-label="Disabled input example" size="lg"/>
         </Form.Group>
      </Col>
    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> الجوال </Form.Label>
         <Form.Control 
          onChange={onChangePhone}
          value={phone}
         type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
       </Form.Group>
    </Col>

    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label>البريد الالكتروني</Form.Label>
         <Form.Control
              onChange={onChangeEmail}
              value={email}
         type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
       </Form.Group>
    </Col>

    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label>نوع التصميم</Form.Label>
         <Form.Control
              onChange={onChangeDesign_type}
              value={design_type}
         type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
     
         {/* <Form.Select  size="Lg">
      <option> </option>
      <option value="1">فيلا</option>
      <option value="2">شقه</option>
      <option value="3">مجمع سكني</option>
    </Form.Select> */}
       </Form.Group>
    </Col>


    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> موقع الارض </Form.Label>
         <Form.Control
              onChange={onChangeLocation}
              value={location}
         type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
   
         {/* <Form.Select  size="Lg">
      <option> اختار المدينة</option>
      <option value="1">رياض</option>
      <option value="2">حائل</option>
      <option value="3">ابها</option>
      <option value="3">جدة</option>
      <option value="3">قصيم</option>
    </Form.Select> */}
       </Form.Group>
    </Col>

    <Col xs={12} md={5} lg={5} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> عنوان الارض </Form.Label>
         <Form.Control 
                onChange={onChangeAddress}
                value={address}
         type="text" placeholder="" aria-label="Disabled input example" size="lg"/>
       </Form.Group>
    </Col>
    <Col xs={12} md={10} lg={10} className='align-items-center p-3 '>
          <Form.Group controlId="formFile" className="mb-3">
         <Form.Label> وصف المشروع</Form.Label>
         <Form.Control 
            onChange={onChangedesc}
            value={desc}
         as="textarea" rows={3} placeholder="" aria-label="Disabled input example" size="lg"/>
       </Form.Group>
    </Col>

 
  
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className="mt-3">
   
      <Button onClick={AddRequest} variant="contained" size='lg' className='btn-grad m-2'>
        ارسال
      </Button>
      {
                    isPress? loading?
                    <h4>  <Spinner animation="border" variant="success"  size="lg"/>  </h4>
                  :<h4>تم الانتهاء</h4>:null
                }
      </Grid>
      <ToastContainer />
    </>
  )
}

export default Request
