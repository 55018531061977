
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


  //to push notifaction
const notify = (message,type) =>
{
if(type === "success")
toast.success(message);
  else if (type === "error")
  toast.error(message)
  else if (type === "warn")
  toast.warn(message)
  
};

export default notify