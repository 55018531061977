import React, { useState } from 'react'
import { Col,Card,Row, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DeleteProject } from '../../redux/Actions/ProjectAction'

const AdminAllProjectsCard = ({title,img,item,id,location,projectdate}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    const handelDelete=async()=>{
    await dispatch(DeleteProject(id))
        setShow(false);
        window.location.reload();
    }

    return (
        <Col xs="12" sm="6" md="5" lg="4" className="d-flex">
            <Card
                className="my-2"
                style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "8px",
                    border: "none",
                    backgroundColor: "#FFFFFF",
                }}>
                <Row className="d-flex justify-content-center px-2">
                    <Col className=" d-flex justify-content-between">
                        <div  onClick={handleShow} className="d-inline item-delete-edit">ازاله</div>
                        <Link to={`/admin/EditProject/${id}`} >
                        <div className="d-inline item-delete-edit">تعديل</div>
                        </Link>
                    </Col>
                </Row>
                <Link to={`/admin/EditProject/${id}`} style={{ textDecoration: "none" }}>
                    <Card.Img style={{ height: "228px", width: "100%" }}  src={'https://api.almenwaleng.com/storage/app/'+img} />
                    <Card.Body>
                        <Card.Title>
                            <div className="card-title">
                          {title}
                            </div>
                        </Card.Title>
                        <Card.Text>
                            <div className="d-flex justify-content-between">
                                <div className="card-rate">{location}</div>
                                <div className="d-flex">
                                    <div className="card-currency mx-1">{projectdate}</div>
                                </div>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Link>
            </Card>





            <Modal show={show} onHide={handleClose}>
        <Modal.Header className='font' >
          <Modal.Title className='font'>حذف المنتج </Modal.Title>
        </Modal.Header>
        <Modal.Body className='font'>هل انت متاكد من الحذف!</Modal.Body>
        <Modal.Footer>
          <Button className='font' variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button className='font' variant="danger" onClick={handelDelete}>
            تاكيد 
          </Button>
        </Modal.Footer>
      </Modal>


        </Col>
    )
}

export default AdminAllProjectsCard
