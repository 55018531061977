

import React from 'react'
import Social_media from '../../Components/Social_media';
import Menu from '../../Components/Menu';
import { Box,Grid} from '@mui/material'
import BgVideo from '../../Components/bgVideo';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import OurClients from '../../Components/OurClients';
import Services from '../../Components/services';
import Typed from 'react-typed';
import ProjectContainer from '../../Components/Project/ProjectContainer';
import Pagination from '../../Components/Uitily/Pagination'
import { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { getAllProject } from '../../redux/Actions/ProjectAction';
import { getAllProjectpage } from '../../redux/Actions/ProjectAction';
const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProject(6));
  }, [])
const Project= useSelector(state=> state.allProjects.Project)
const loading = useSelector(state=> state.allProjects.loading)

 //to get page count
 let items=[]; let pageCount=0; 
 try {

if(Project.data)
items = Project.data;
else
items =[]

  if(Project.paginationResult)
  pageCount=Project.paginationResult.numberofpages
else
pageCount=[]
 } catch (error) { }

    //when press pagintion
    const getPage =(page)=>{
      dispatch(getAllProjectpage(page));
      // console.log(page)
    }

  return (
    <section className='home_page' >
  <Row className='justify-content-center '>
  <div className="title text-center">
  <h2 >المنوال لاستشارات الهندسية </h2>
           <Typed
            
                   strings={["نحول الفكرة الى واقع مفيد ومربح.", "تقديم حلول ذات أفضل قيمة وفي الوقت المحدد لتلبية احتياجات ومواصفات عملائنا", "العملاء أولويتنا القصوى وخدماتنا الهندسية متنوعة وتقدم الحل الكامل ونعمل في جميع مناطق المملكة "]}
                   typeSpeed={50} backSpeed={50}   backDelay={1} loop smartBackspace className='typed'  />
                </div>
  </Row>
    <Container fluid>
    <Row className='justify-content-center '>
        <Col  xs={1} md={1} lg={1} className='align-items-center'>
        
        <div className='media'>
        <Social_media/>
        </div>
       </Col>
        <Col item xs={12} md={10} lg={10} className='align-items-center mt-5'>
         <ProjectContainer  data={Project.data} loading={loading}/>
         <Pagination onPress={getPage}  pageCount={pageCount}/>

         {/* {
      pageCount> 1?(<Pagination pageCount={pageCount} onPress={getPage}/>):null
    } */}
        </Col>
        <Col item xs={1} md={1} lg={1} className='justify-content-center align-items-center'>
        <div className='menu'>
               <Menu/>
               </div>
  </Col>
  
   </Row>
</Container>
<BgVideo/>
<Row className='justify-content-center '>
<Services/>
</Row>
<OurClients/>
</section>
  )
}

export default Home