import React from 'react'
import { Col, Image, Row, Spinner } from 'react-bootstrap'
import AdminAllProjectsCard from './AdminAllProjectsCard'

const AdminAllProjects = ({data,loading}) => {
    return (
        <div>
            <div className='admin-content-text'>ادارة جميع  المشاريع</div>
            <Row className='justify-content-start'>
                {
             loading === false?(
             data?(
               data.map((item,index) =>{
                  return (
                  <AdminAllProjectsCard key={index} img={item.imagepath}  title={item.projectname} id={item.id} location={item.location} projectdate={item.projectdate}/>
                  )
                 })
             ) :<h4>لايوجد  مشاريع</h4>
             ): <Row className='justify-content-center'>
             <Spinner  animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
         </Spinner>
           </Row> 
  }
            </Row>
            
        </div>
    )
}

export default AdminAllProjects
