import { GET_ALL_REQUEST,GET_ERROR,CREATE_REQUEST,GET_ONE_REQUEST,} from '../Type'
import UseGetData from '../../Hooks/UseGetData'
import { UseInsertData, UseInsertDataWithImage } from '../../Hooks/UseInsertData';
import BaseURL from '../../Api/BaseURL';
 import {UseUpdateDataWithImage} from '../../Hooks/UseUpdate.js'

//action display all category 
export const getAllRequest =()=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/public/api/projects/all');  
        const response =await UseGetData('/public/api/demand/all');
        // console.log(res.data)
        dispatch({
            type:GET_ALL_REQUEST,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}

//action createCategory
export const createRequest =(Data)=> async (dispatch)=>{
    try{
        const response =await UseInsertData('/public/api/demand/create',Data);
        console.log(response)
        dispatch({
            type:CREATE_REQUEST,
            payload:response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
          
        } )  
        console.log(e)
    }
}


//action display one category 
export const getOneRequest=(id)=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/api/v1/categories');  
        // console.log(res.data)
        const response = await UseGetData(`/public/api/projects/getproject/${id}`);
        dispatch({
            type:GET_ONE_REQUEST,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}

