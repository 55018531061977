import React from 'react'

import { Col, Image, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Social_media from '../Social_media';
import Menu from '../Menu';
import { Fade } from 'react-reveal';
import Request from './Request';
const DesignRequest = () => {
  return (
 
    <Row className='justify-content-center '>
    <Col  xs={1} md={1} lg={1} className='align-items-center'>
        <div className='media_des'>
        <Social_media/>
        </div>        
   </Col>
    
      <Col item xs={12} md={10} lg={10}>
      <Fade bottom>
               <Row className='justify-content-center '>
               <div className='titel'>طلبات التصميم</div>
<Request/>
                
</Row>
                   </Fade>
      </Col>
    
      <Col  xs={1} md={1} lg={1} className='align-items-center'>
      <div className='menu_des'>
               <Menu/>
               </div>
      </Col>
 
</Row>
   

  )
}

export default DesignRequest
