import React from 'react'
import { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getOneProject } from '../../redux/Actions/ProjectAction';
const ProjectsDetilsHook = (id) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOneProject(id));
      }, [])
  
      const oneProjects= useSelector(state=> state.allProjects.ProjectDetalis)//ارجاع بيانات المنتج
    //   const Project= useSelector(state=> state.allProjects.Project)
    //to show item
    let item = [];   

try {
  if(oneProjects.data)
  item = oneProjects.data;
  else
  item =[] 
} catch (error) {
  
}




console.log(oneProjects)
return[item]
}
export default ProjectsDetilsHook
