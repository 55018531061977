import React, { useEffect, useState } from 'react'
import notify from '../../Custom_Hook/UseNotifaction'
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom'
import { LoginUser } from '../../redux/Actions/AuthAction';

const LoginHook = () => {
    const dispatch = useDispatch();
  const navigate=useNavigate();
     
  
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(true)
    const [isPress,setIsPress] = useState(false)

    const onChangeEmail=(e)=>{
        setEmail(e.target.value)
      }
      const onChangePassword=(e)=>{
        setPassword(e.target.value)
      }

      const validationValues =() => {
        if(email === ""){
            notify("من فضلك ادخل الايميل ","error")
            return;
        }
        if(password === ""){
            notify("من فضلك ادخل كلمة السر ","error")
            return;
        }

    
      }

      const onSubmit= async()=>{
        validationValues();
        setIsPress(true)
        setLoading(true)
        await dispatch(LoginUser({email,password}))
        setLoading(false)
        setIsPress(false)
      }

      const res= useSelector(state=> state.AuthReducers.UserLogin)

      useEffect(() => {
        if(loading === false){
            if(res){
                console.log(res.data)
                if(res.data.message === "User login successfully."){
                  // localStorage.setItem("token",res.data.token)
                  // localStorage.setItem("user",JSON.stringify(res.data.data))
                  notify("تم تسجيل دخولك بنجاح","success")
                  setTimeout(() => {
                    // navigate('/')
                    window.location.href="/admin/AllProjects"
                  }, 1500);
                }else{
                  // localStorage.removeItem("token") 
                  // localStorage.removeItem("user")
                }

                if(res.data.message === "Unauthorised."){
                  // localStorage.removeItem("token") 
                  // localStorage.removeItem("user")
                  notify("خطا في كلمة المرور او البريد","error")
                }
                setLoading(true)
            }
        }
     
    }, [loading])

      return [email,password,loading,onChangeEmail
        ,onChangePassword,onSubmit,isPress]

}

export default LoginHook
