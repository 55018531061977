import { GET_ALL_REQUEST,GET_ERROR,CREATE_REQUEST,GET_ONE_REQUEST } from '../Type'
const inital ={
    Request :[],
  oneRequest:[],
    loading : true,
}
const DesignRequestReduce = (state= inital, action) => {
switch (action.type) {

    case GET_ALL_REQUEST:
      return{
        ...state,
        Request:action.payload,
       loading:false,
      }

      case GET_ONE_REQUEST:
      return{
       oneRequest :action.payload,
       loading:false,
      }

case CREATE_REQUEST:
  return{
    Request:action.payload,
    loading:false,
  }

      case GET_ERROR: return {
        Request:action.payload,
        loading:true,
      }
    default:
return state;
}
}

export default DesignRequestReduce