

import React from 'react'
import { Box, Button, Grid, Paper, Stack} from '@mui/material'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Social_media from './Social_media';
import Menu from './Menu';
import { Fade } from 'react-reveal';

const ServicesAll = () => {
  return (
    <section id="services" className="services">

   
    
<Row className='justify-content-center'>

<div className='titel'> خدماتنا </div>

    <Col  xs={1} md={1} lg={1} className='align-items-center'>
        <div className='media_serv'>
        <Social_media/>
        </div>        
   </Col>
    
   
              
 
   <Col  xs={12} md={10} lg={10} className='align-items-center'>
   <Fade top>
   <Row className='justify-content-center'>
               <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
           
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-building-house"></i></div>
                  <h4><a href=""> إدارة المشاريع </a></h4>
                  <p>
                  فريق 
                  ادارة مشاريع محترف من جميع التخصصات الهندسية يعمل وفق قياسية PMI لإدارة المشاريع التي يتم تكليفنا بها من قبل العملاء وفق عقود 
                  ادارة مشاريع في 
                  جميع مناطق المملكة العربية السعودية
                   فريقنا جاهزة لإدارة مشاريع الانشاءات و
  التصنيع والخدمات الهندسية
                  </p>
                </div>
              </Col>
    



              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-brush"></i></div>
                  <h4><a href=""> التصميم الداخلي والديكور  </a></h4>
                  <p>
                  فريق عمل من مهندسات ومهندسين التصميم الداخلي والديكور لأستخدام افضل البرامج المرخصه وبخبرة كبيرة في مجال الفن التشكيلي الممزوج بالتخصص الهندسي المحترف لتطوير التصاميم الداخلية الراقية ذات الجودة العالية جدا في الاضاءة وتجانس الالون وإختيار الفرش والمواد والتأثبيت حيث يتم  إنتاج لوحات تصميم داخلي ومناظير فائقة الروعة وقابلة للتنفيذ وبأسعار منافسة جدا

                  </p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-building"></i></div>
                  <h4><a href="">   التصميم الخارجي  </a></h4>
                  <p>
                  تصميم معماري وإنشائي وكهربائي وميكانيكي شامل التكييف وصحي وفق كود  البناء السعودي مع إستخراج جميع التراخيص اللازمة من خلال بوابة بلدي والأمانات والبلديات في الرياض وجدة ومكة المكرمة والجوف ورفحاء والمنطقة الشرقية وأبها من خلال فروع مكانبنا اللتي يحتوي كل منها على طاقم عمل هندسي كامل وحساب الكميات والاحمال الانشائية وحساب الاحمال الكهربائية والرفوعات المساحية

                  </p>
                </div>
              </Col>


              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bxs-business"></i></div>
                  <h4><a href="">  التخطيط العمراني </a></h4>
                  <p>
تطوير الحلول المناسبة للمدينة الذكية ومدن المستقبل في مجالات التعامل مع شيح المياه والنفايات والتلوث والازدحام حيث نقوم بتطوير حلول تتواكب مع مقررات برامج الاستدامة الدولية والمحلية.
                  </p>
                </div>
              </Col>


              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bxs-radiation"></i></div>
                  <h4><a href=""> عملية الهندسة الصناعية </a></h4>
                  <p>
التصميم والأشراف على تصاميم المصانع وإعتمادها من هيئة المدن الصناعية.
                  </p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-cube-alt"></i></div>
                  <h4><a href=""> الدراسات الهيدرولوجية</a></h4>
                  <p>
القيام بأعمال الدراسات الهيدرولوجية وتطوير افضل الحلول الهندسية للحماية من الفيضانات والسيول والكوارث البيئية
                  </p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-shape-triangle"></i></div>
                  <h4><a href="">الاشراف الهندسي</a></h4>
                  <p>
تطوير خطة المشروع ومتايعة إعمال المشروع بشكل دوري من خلال عقود إشراف هندسي بمدة المشروع اوبعدد الزيارات ويشمل ذلك على تقارير لكل زيارة او تقارير دورية  واستلام الاعمال الهندسية المنفذة والمواد من المقولين

                  </p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-meteor"></i></div>
                  <h4><a href=""> السلامة والاطفاء</a></h4>
                  <p>
التصميم والاشراف الهندسي على شبكات اطفاء الحريق وفق الكود الخليجي
                  </p>
                </div>
              </Col>
              </Row>
               </Fade>
              </Col>

            
            
                
           
    
    
      <Col  xs={1} md={1} lg={1} className='align-items-center'>
      <div className='menu_serv'>
               <Menu/>
               </div>
      </Col>
     
    </Row>  
    
   
   
   
    </section>
  )
}

export default ServicesAll





