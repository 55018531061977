import React from 'react';
import { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { createRequest } from '../../redux/Actions/DesignRequestActions';
import { useEffect } from 'react';
import notify from '../../Custom_Hook/UseNotifaction';  



const DesginRequstHook = () => {

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [design_type, setDesign_type] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [desc,setdesc] = useState('');
    const [address,setAddress] = useState('');
    const [loading,setLoading] = useState(true)
    const [isPress,seIsPress] = useState(false)

//to change name state
const onChangeName=(e)=>{
    e.persist();
    setName(e.target.value)
}

const onChangePhone=(e)=>{
    e.persist();
    setPhone(e.target.value)
}

const onChangeLocation=(e)=>{
    e.persist();
    setLocation(e.target.value)
}


const onChangeAddress=(e)=>{
    e.persist();
    setAddress(e.target.value)
}

const onChangeDesign_type=(e)=>{
    e.persist();
    setDesign_type(e.target.value)
}


const onChangeEmail=(e)=>{
    e.persist();
    setEmail(e.target.value)
}


const onChangedesc=(e)=>{
    e.persist();
    setdesc(e.target.value)
}


const res= useSelector(state=> state.allDesignRequest.Request)



    const AddRequest = async(e)=>{
        e.preventDefault();
        if(name ===""|| phone ==="" || location ==="" || email ===""  || desc === "" || address==="" || design_type ==="" ){
            // console.log('من فضلك اكل البايانات')
           // <h4> من فضلك اكل البايانات</h4>
           notify(" من فضلك اكل البايانات  ","warn");
           return;
       }
        const formData =new FormData();
        formData.append("name",name)
        formData.append("phone",phone)
        formData.append("design_type",design_type)
        formData.append("location",location)
        formData.append("email",email)
        formData.append("desc",desc)
        formData.append("address",address)
        // formData.append("created_at",created_at)
        if(loading)
        setLoading(true)
        seIsPress(true)
        await dispatch(createRequest(formData)) 
        setLoading(false)
        console.log("تم الحفظ")
    }


    useEffect(() => {
        if(loading === false) {
        setName("")
        setPhone("")
        setLocation("")
        setEmail(" ")
        setdesc(" ")
        setDesign_type(" ")
        setAddress(" ")
        console.log('تم الانتهاء')
      setLoading(true)
      seIsPress(false)
      setTimeout(()=>seIsPress(false),1200)
      if(res.status === 200){
         notify("تم ارسال طلبك  بنجاح ","success");
      }else{
         notify(" هناك مشكلة في عملية الاضافة ","error");
      }
      
    if(res)
    console.log(res.status)

        }
     }, [loading])


    return[onChangeName,onChangePhone,onChangeLocation,onChangeAddress,onChangeDesign_type,onChangeEmail,onChangedesc,name,phone,design_type,location,email,desc,address,AddRequest,isPress,loading ]

}

export default DesginRequstHook
