import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AdminSideBar from '../../Components/Admin/AdminSideBar'
import AdminAllOrders from '../../Components/Admin/AdminAllOrders'
import Pagination from '../../Components/Uitily/Pagination'
import { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { getAllRequest } from '../../redux/Actions/DesignRequestActions';
import { getAllProjectpage } from '../../redux/Actions/ProjectAction';
const AdminAllOrdersPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAllRequest());
    }, [])
  const Request= useSelector(state=> state.allDesignRequest.Request)
  const loading = useSelector(state=> state.allProjects.loading)
    return (
        <Container >
            <Row className='py-3'>
                <Col sm="3" xs="2" md="2">
                    <AdminSideBar />
                </Col>

                <Col sm="9" xs="10" md="10">
                    <AdminAllOrders data={Request.data} loading={loading} />
                    <Pagination />
                </Col>
            </Row>
        </Container>
    )
}
export default AdminAllOrdersPage
