import React from 'react'
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'
import { Form,Row,Col,Container ,input, Spinner } from 'react-bootstrap'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import Menu from '../Menu';
import Social_media from '../Social_media';
import {Fade} from 'react-reveal/Fade';
import LightSpeed from 'react-reveal/LightSpeed';
import { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { getAllGallery, getAllGalleryT2, getAllGalleryT3 } from '../../redux/Actions/GalleryActions';

const Gallery = () => {

  const [value, setValue] = React.useState(' ');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getAllGallery());
      dispatch(getAllGalleryT2());
      dispatch(getAllGalleryT3());
    }, [])
  const images= useSelector(state=> state.allGallery.Gallery)
  const images2= useSelector(state=> state.allGallery.Gallery2)
  const images3= useSelector(state=> state.allGallery.Gallery3)
  const loading = useSelector(state=> state.allGallery.loading)

  console.log(images3.data)
  console.log(loading)


  return (
<section className='gallery_page'>


<div className="title text-center">
  <h2 >  معرض الصور </h2>
  </div>
  <Row className='justify-content-center '>
    
    <Col  xs={1} md={1} lg={1} className='align-items-center'>
        <div className='media_gall'>
        <Social_media/>
        </div>        
   </Col>

   <Col item xs={12} md={10} lg={10}>
     
               <Row className='justify-content-center '>
 

  <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
       <Grid container 
    direction="row"
    justifyContent="center"
    alignItems="center"
    width="100%"
   
    >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="صور مشاريع" value="1" />
            <Tab label="صور مناسبات" value="2" />
            <Tab label="الشهادات" value="3" />
          </TabList>
          </Grid>
        </Box>
       
        <TabPanel value="1"  height="80vh">
        
<SlideshowLightbox className='row portfolio-container justify-content-center' showThumbnails={true}>
{
  loading === false?(
  images.data?(
    images.data.map((item)=> { 
      return(<img className='w-size rounded' src={'https://api.almenwaleng.com/storage/app/'+item.imagepath} />)})
  ):<h4>لايوجد صور</h4>
  ):<Row className='justify-content-center'>
  <Spinner  animation="border" role="status">
<span className="visually-hidden">Loading...</span>
</Spinner>
</Row> 

}
 


</SlideshowLightbox>
        </TabPanel>
       
        <TabPanel value="2"  height="80vh">
<SlideshowLightbox className='row portfolio-container justify-content-center' showThumbnails={true}>
{
  loading === false?(
  images2.data?(
     images2.data.map((item)=> { 
      return(<img className='w-size rounded' src={'https://api.almenwaleng.com/storage/app/'+item.imagepath} />)})
  ):<h4>لايوجد صور</h4>
  ):<Row className='justify-content-center'>
  <Spinner  animation="border" role="status">
<span className="visually-hidden">Loading...</span>
</Spinner>
</Row> 
}
</SlideshowLightbox> 
        </TabPanel>
        <TabPanel value="3"  >
      
<SlideshowLightbox className='row portfolio-container justify-content-center' showThumbnails={true}>

{
  loading === false?(
  images3.data?(
     images3.data.map((item)=> { 
      return(<img className='w-size rounded' src={'https://api.almenwaleng.com/storage/app/'+item.imagepath} />)})
  ):<h4>لايوجد صور</h4>
  ):<Row className='justify-content-center'>
  <Spinner  animation="border" role="status">
<span className="visually-hidden">Loading...</span>
</Spinner>
</Row> 
}

</SlideshowLightbox> 
        </TabPanel>
      </TabContext>

 </Row>

  </Col>

  <Col  xs={1} md={1} lg={1} className='align-items-center'>
      <div className='menu_gall'>
               <Menu/>
               </div>
      </Col>
      
</Row>  



</section>

  )
}

export default Gallery

