import React from 'react'
import { GET_ALL_Images, GET_ALL_Images2,GET_ALL_Images3,GET_ERROR,CREATE_GALLERY} from '../Type'
const inital ={
    Gallery :[],
    Gallery2 :[],
    Gallery3 :[],
    loading : true,
}
const GalleryReduce = (state= inital, action) => {
    switch (action.type) {

        case GET_ALL_Images:
          return{
            ...state,
            Gallery:action.payload,
           loading:false,
          }
    
          case GET_ALL_Images2:
            return{
              ...state,
              Gallery2:action.payload,
             loading:false,
            }

            case GET_ALL_Images3:
                return{
                  ...state,
                  Gallery3:action.payload,
                 loading:false,
                }
 
    
    case CREATE_GALLERY:
      return{
        Gallery:action.payload,
        loading:false,
      }
    
          case GET_ERROR: return {
            Gallery:action.payload,
            loading:true,
          }
        default:
    return state;
    }
}

export default GalleryReduce


 