import BaseURL from "../Api/BaseURL";



const UseInsertDataWithImage = async (url,parmas) => {
    const config={
        headrs:{"Content-Type":"multipart/form-data"}
    }
    const res = await BaseURL.post(url,parmas,config)
    return res;
    }


const UseInsertData = async (url,parmas) => {
const res = await BaseURL.post(url,parmas)
return res;
}

export {UseInsertData,UseInsertDataWithImage} 