import { CREATE_NEW_USER,LOGIN_USER } from '../Type'
import { UseInsertData } from '../../Hooks/UseInsertData';

//action create NEW USER
export const createNewUser =(data)=> async (dispatch)=>{
    try{
        const response =await UseInsertData(`/api/v1/auth/signup`,data);
        dispatch({
            type:CREATE_NEW_USER,
            payload : response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:CREATE_NEW_USER,
            payload :e.response,
        } )  
    }
}

//LOGIN  USER
export const LoginUser =(data)=> async (dispatch)=>{
    try{
        const response =await UseInsertData(`/public/api/login`,data);
        dispatch({
            type:LOGIN_USER,
            payload : response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:LOGIN_USER,
            payload :e.response,
        } )  
    }
}