import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import avatar from '../../images/avatar.png'
import AddGalleryHook from '../../Custom_Hook/Gallery/AddGalleryHook'
import { ToastContainer } from 'react-toastify'
const AdminAddCategory = () => {

    const [img,type,loading,isPress,onImageChange,onChangeType,SaveImage]=AddGalleryHook()
    return (
        <div>
            <Row className="justify-content-start ">
                <div className="admin-content-text pb-4">اضافه صور جديد</div>
                <Col sm="8">
                    <div className="text-form pb-2">صوره </div>
                    {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
                    <label for="upload-photo">
                        <img src={img} alt="" height="100px" width="120px" style={{cursor:"pointer"}}/>
                        <input type='file' name="photo" id="upload-photo" onChange={onImageChange} />
                        </label>


                        {/* <label for="upload-photo">
                        <img src={img} alt="" height="100px" width="120px" style={{cursor:"pointer"}}/>
                        <input type='file' name="photo" onChange={onImageChange} id="upload-photo"/>
                        </label> */}
               

               <select  value={type}  onChange={onChangeType} className="select mt-3 px-2 ">
                        <option> من فضلك اختار نوع المناسبة</option>
                        <option value="1">صور مشاريع</option>
                        <option value="2">صور مناسبات</option>
                        <option value="3"> الشهادات</option>
                    </select>

                       {/* <input
                        onChange={onChangeType}
                        value={type}
                        type="text"
                        className="input-form d-block mt-3 px-3"
                        placeholder="نوع المناسبة"
                        /> */}

                </Col>
            </Row>
            <Row>
                <Col sm="8" className="d-flex justify-content-end ">
                    <button className="btn-save d-inline mt-2 " onClick={SaveImage}>حفظ التعديلات</button>
                </Col>
            </Row>
            {
                isPress ? loading? <h4 className=''>   
                <Spinner animation="border" />; 
                </h4>:<h4> تم الانتهاء </h4>:null }
            <ToastContainer position="top-right"  hideProgressBar={false} rtl={true}/>
        </div>
    )
}

export default AdminAddCategory
