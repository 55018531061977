
import React from 'react'
import avatar from '../../images/avatar.png'
import { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { useEffect } from 'react';
import notify from '../../Custom_Hook/UseNotifaction';
import { CreateGallery } from '../../redux/Actions/GalleryActions';

const AddGalleryHook = () => {
    const dispatch = useDispatch();
    const [img, setImg] = useState(avatar)
    const [selectedFile, setSelectedFile] = useState(null)
    const [type, setType] = useState('')

    const [loading,setLoading] = useState(true)
    const [isPress,seIsPress] = useState(false)



//to change name state
const onChangeType=(e)=>{
    e.persist();
    setType(e.target.value)
}

    //when image change save it
    const onImageChange=(e)=>{
        if(e.target.files && e.target.files[0]){
            setImg(URL.createObjectURL(e.target.files[0]))
            //حفظ مسار الملف لتخزين
            setSelectedFile(e.target.files[0])
        }
            }



            const res= useSelector(state=> state.allGallery.Gallery)
          
            const SaveImage = async(e)=>{
                e.preventDefault();
                if(type===""|| selectedFile=== null  ){
                    // console.log('من فضلك اكل البايانات')
                   // <h4> من فضلك اكل البايانات</h4>
                   notify(" من فضلك اكل البايانات  ","warn");
                   return;
               }
                const formData =new FormData();
                formData.append("image_type",type)
                formData.append("imagepath",selectedFile)

                if(loading)
                setLoading(true)
                seIsPress(true)
                console.log("جاري التحميل")
                await dispatch(CreateGallery(formData)) 
                setLoading(false)
            }
            useEffect(() => {
                if(loading === false) {
                setImg(avatar)
                setType("")
                setSelectedFile(null)

                console.log('تم الانتهاء')
              setLoading(true)
              seIsPress(false)
              setTimeout(()=>seIsPress(false),1200)
              if(res.status === 200){
                 notify("تم اضافة المشروع بنجاح بنجاح","success");
              }else{
                 notify(" هناك مشكلة في عملية الاضافة ","error");
              }
              
            if(res)
            console.log(res.status)

                }
             }, [loading])


             return[img,type,loading,isPress,onImageChange,onChangeType,SaveImage]
}

export default AddGalleryHook
