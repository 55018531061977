import {CREATE_NEW_USER,LOGIN_USER } from '../Type'
const inital ={
    CreateUser :[],
    UserLogin :[],
    loading : true,
}
const AuthReducers= (state= inital, action) => {
switch (action.type) {
    case CREATE_NEW_USER:
      return{
        ...state,
        CreateUser:action.payload,
      
      }

      case LOGIN_USER:
        return{
          ...state,
          UserLogin:action.payload,
        }

    default:
return state;
}
}

export default AuthReducers