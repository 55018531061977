import { GET_ALL_PROJECT,GET_ERROR,CREATE_PROJECT,GET_PROJECT_DETALIS,UPDATE_PROJECT,DELETE_PROJECT } from '../Type'
import UseGetData from '../../Hooks/UseGetData'
import { UseInsertDataWithImage } from '../../Hooks/UseInsertData';
import UseDeleteData from '../../Hooks/UseDelete';
import { UseUpdateDataWithImage } from '../../Hooks/UseUpdate';


//action display all category 
export const getAllProject =(limit)=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/public/api/projects/all');  
        const response =await UseGetData(`/public/api/projects/all/${limit}/0`);
        // console.log(res.data)
        dispatch({
            type:GET_ALL_PROJECT,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}



//action createCategory
export const createProject =(formData)=> async (dispatch)=>{
    try{
        const response =await UseInsertDataWithImage('/public/api/projects/create',formData);
        dispatch({
            type:CREATE_PROJECT,
            payload:response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}


//action delete products 
export const DeleteProject =(id)=> async (dispatch)=>{
    try{
        const response =await UseDeleteData(`/public/api/projects/destroy/${id}`);
        dispatch({
            type:DELETE_PROJECT,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}


// action pagination page
export const getAllProjectpage =(page)=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/api/v1/categories');  
        // console.log(res.data)
        const response =await UseGetData(`/public/api/projects/all/6/${page}`);
        dispatch({
            type:GET_ALL_PROJECT,
            payload : response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}



//action display one category 
export const getOneProject=(id)=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/api/v1/categories');  
        // console.log(res.data)
        const response = await UseGetData(`/public/api/projects/getproject/${id}`);
        dispatch({
            type:GET_PROJECT_DETALIS,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}

export const UpdateProjects =(id,formData)=> async (dispatch)=>{
    try{
        const response =await UseUpdateDataWithImage(`public/api/projects/edit/${id}`,formData);
        dispatch({
            type:UPDATE_PROJECT,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}





