import React from 'react'
import { Form,Row,Col,Container ,input, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoginHook from '../../Custom_Hook/Auth/LoginHook'
import { ToastContainer } from 'react-toastify'

const LoginPage = () => {

    const [email,password,loading,onChangeEmail,onChangePassword,onSubmit,isPress]=LoginHook()

    return (
            <Container style={{ minHeight: "650px" }}>
                <Row className="py-5  mt-5 d-flex justify-content-center ">
                    <Col sm="6" className="d-flex flex-column align-items-center ">
                        <label className="mx-auto title-login">تسجيل الدخول</label>
                        <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="d-flex align-items-center  mx-aut"> البريد الالكتروني</Form.Label>
                        <Form.Control 
                         value={email}
            onChange={onChangeEmail} 
            type="email"
             placeholder="ادخل البريد الالكتروني" 
             aria-label="Disabled input example"  className="user-input text-center mx-auto" size="lg"/>
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="mx-aut">كلمة المرور</Form.Label>
                        <Form.Control
                          value={password}
                          onChange={onChangePassword}
                        type="text" placeholder=" ادخل كلمة المرور" aria-label="Disabled input example"  className="user-input text-center mx-auto" size="lg"/>
                        </Form.Group>
                        <button onClick={onSubmit} className="btn-save d-inline mt-2 ">تسجيل الدخول</button>
                        {/* <label className="mx-auto my-4">
                            ليس لديك حساب ؟{" "}
                            <Link to="/register" style={{textDecoration:'none'}}>
                                <span style={{ cursor: "pointer" }} className="text-danger">
                                    اضغط هنا
                                </span>
                            </Link>
                        </label> */}
<br></br>
{isPress === true ? (loading === true? (
        <Spinner animation="border" role="status">
        <span className="visually-hidden">جاري تسجيل الدخول...</span>
      </Spinner>
):null):null}
                    </Col>
                    <label className="mx-auto my-4">
                    {/* <Link to="/admin/AllProjects" style={{textDecoration:'none'}}>
                        <span style={{ cursor: "pointer" }} className="text-danger">
                            الدخول ادمن
                        </span>
                    </Link> */}

                    {/* <Link to="/user/allorders" style={{textDecoration:'none'}}>
                        <span style={{ cursor: "pointer" }} className="text-danger mx-3">
                            الدخول مستخدم
                        </span>
                    </Link> */}
                </label>
                </Row>
                <ToastContainer position="top-center"  hideProgressBar={false} rtl={true}/>
            </Container>
    )
}

export default LoginPage
