import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { Form,Row,Col,Container  } from 'react-bootstrap'

// import { MantineLogo } from '@mantine/ds';
const Footer = () => {
  return (
    <div>
 <footer id="footer">
    <div className="footer-top">
    <Container>
      <Row>
      <Col xs={6} lg={3} md={6} className='footer-links'> 
      <h4>روابط مفيدة</h4>
      <ul>
        <li> <a href="/">الرئيسية</a> <i class='bx bx-chevron-left'></i></li>
        <li><a href="/about">من نحن <i class='bx bx-chevron-left'></i></a></li>
        <li> <a href="/">خدماتنا</a> <i class='bx bx-chevron-left'></i> </li>
        <li><a href="/"> مكتبة الصور </a> <i class='bx bx-chevron-left'></i> </li>
        <li> <a href="#">سياسة الخصوصية</a> <i class='bx bx-chevron-left'></i></li>
      </ul>
    </Col>

    <Col xs={6} l lg={3} md={6} className='footer-links'> 
      <h4>خدماتنا</h4>
      <ul>
        <li> <a href="#">  ادارة مشاريع</a> <i class='bx bx-chevron-left'></i> </li>
        <li> <a href="#">   التصميم الداخلي والديكور</a>  <i class='bx bx-chevron-left'></i> </li>
        <li><a href="#">    التصميم الخارجي  </a>  <i class='bx bx-chevron-left'></i>  </li>
        <li><a href="#">    التخطيط العمراني </a> <i class='bx bx-chevron-left'></i>  </li>
        <li><a href="#">     عملية الهندسة الصناعية</a> <i class='bx bx-chevron-left'></i> </li>
        <li> <a href="#">     الاشراف الهندسي</a>  <i class='bx bx-chevron-left'></i> </li>
        <li> <a href="#">      السلامة والاطفاء</a> <i class='bx bx-chevron-left'></i> </li>
      </ul>
    </Col>


    <Col xs={6} l lg={3} md={6} className="footer-contact"> 
    <h4>تواصل معنا</h4>
      <p>
            المملة العربية السعودية حائل 
            <br></br>
              <strong>Phone:</strong>+966 506003787 
              <br></br>
              <strong>Email:</strong> info@example.com
            </p>
    </Col>
    <Col xs={6} l lg={3} md={6}  className="footer-info">
      <h3> المنوال لاستشارات الهندسية</h3>
      <p>  تقديم حلول ذات أفضل قيمة وفي الوقت المحدد لتلبية احتياجات ومواصفات عملائنا  </p>
      <div className="social-links mt-3">
        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
        <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
      </div>
    </Col>





      </Row>
      </Container>
    </div>
   

    <Container>
      <div className="copyright">
        حقوق النشر    &copy;   جميع الحقوق محفوظة <strong><span>المنوال 2023</span></strong>   
      </div>
      <div className="credits">
       صمم بواسطة <a href="https://bootstrapmade.com/">مؤسسة حواسيب  </a>
        الجوال <a href="https://bootstrapmade.com/"> +966 506003787  </a>
      </div>
    </Container>





 </footer>
  </div>
  )
}

export default Footer








