import React from 'react'
import { Col, Image, Row, Spinner } from 'react-bootstrap'
import AdminAllOrdersItem from './AdminAllOrdersItem'

const AdminAllOrders = ({data,loading}) => {
    return (
        <div>
            <div className='admin-content-text'>ادارة جميع الطلبات</div>
            <Row className='justify-content-start mt-3'>

            {
             loading === false?(
             data?(
               data.map((item,index) =>{
                  return (
                  <AdminAllOrdersItem key={index} name={item.name}  type={item.design_type} location={item.location} id={item.id} />
                  )
                 })
             ) :<h4>لايوجد  مشاريع</h4>
             ): <Row className='justify-content-center'>
             <Spinner  animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
         </Spinner>
           </Row> 
  }
              
            </Row>
        </div>
    )
}

export default AdminAllOrders
