import React from 'react'
import { Col, Image, Row, Spinner } from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import { useEffect } from 'react';
import Projects from './Projects';
import {useSelector,useDispatch} from 'react-redux'
import { getAllProject } from '../../redux/Actions/ProjectAction';


const ProjectContainer = ({data,loading}) => {

  return (
    <section className="Projects">
   <Fade bottom>
   <Row className='justify-content-center '>
       {
             loading === false?(
             data?(
               data.map((item,index) =>{
                  return (
                  <Projects key={index} img={item.imagepath}  title={item.projectname} id={item.id} />
                  )
                 })
             ) :<h4>لايوجد  مشاريع</h4>
             ): <Row className='justify-content-center'>
             <Spinner  animation="border" role="status">
           <span className="visually-hidden">Loading...</span>
         </Spinner>
           </Row> 
  }
</Row>
</Fade>
</section>
  )
}

export default ProjectContainer
// data.slice(0,6).map((item,index) 