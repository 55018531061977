
import './App.css';
import { Button} from '@mui/material';
import NavBar from './Components/Uitily/NavBar';
import Home from './Page/Home/Home';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About_company from './Components/About_company';
import Footer from "./Components/Uitily/Footer";

import DesignRequest from './Components/DesignRequest/DesignRequest';
import Contact from './Components/contact';

import ServicesAll from './Components/servicesAll';
import AdminAllOrdersPage from "./Page/Admin/AdminAllOrdersPage";
import AdminOrderDetalisPage from "./Page/Admin/AdminOrderDetalisPage";
import AdminAddBrandPage from "./Page/Admin/AdminAddBrandPage";
import AdminAddCategoryPage from "./Page/Admin/AdminAddCategoryPage";
import AdminAddSubCategoryPage from "./Page/Admin/AdminAddSubCategoryPage";
import UserAllOrdersPage from "./Page/User/UserAllOrdersPage";
import UserFavoriteProductsPage from "./Page/User/UserFavoriteProductsPage";
import UserAllAddresPage from './Page/User/UserAllAddresPage';
import UserAddAddressPage from './Page/User/UserAddAddressPage';
import UserEditAddressPage from './Page/User/UserEditAddressPage';
import UserProfilePage from "./Page/User/UserProfilePage";
import LoginPage from "./Page/Auth/LoginPage";
import RegisterPage from "./Page/Auth/RegisterPage";
import ProjectsDetils from './Components/Project/ProjectsDetils';
import AdminAddProjectPage from './Page/Admin/AdminAddProjectPage';
import AdminAllProjectsPage from './Page/Admin/AdminAllProjectsPage';
import AdminEditProjectsPage from './Page/Admin/AdminEditProjectsPage';
import Gallery from './Components/Gallery/Gallery';
// import AdminEditProductsPage from "./Page/Admin/AdminEditProductsPage";+

function App() {
  return (
    <>
      <NavBar/>
<Container fluid>
    <BrowserRouter>
    <Routes>
   <Route index element={<Home/>}/>
   <Route path="/about" element={<About_company/>}/>  
   <Route path="/detils/:id" element={<ProjectsDetils/>}/> 
   <Route path="/Request" element={<DesignRequest/>}/> 
   <Route path="/contact" element={<Contact/>}/> 
   <Route path="/Gallery" element={<Gallery/>}/> 
   <Route path="/services" element={<ServicesAll/>}/> 


   <Route path="/login" element={<LoginPage/>}/>
<Route path="/register" element={<RegisterPage />} />

   <Route path="/admin/AllProjects" element={<AdminAllProjectsPage />} />
<Route path="/admin/allorders" element={<AdminAllOrdersPage />} />
          <Route path="/admin/orders/:id" element={<AdminOrderDetalisPage />} />
          <Route path="/admin/EditProject/:id" element={<AdminEditProjectsPage/>} />
          <Route path="/admin/addbrand" element={<AdminAddBrandPage />} />
          <Route path="/admin/addcategory" element={<AdminAddCategoryPage />} />
          <Route path="/admin/addsubcategory" element={<AdminAddSubCategoryPage />} />
          <Route path="/admin/addProject" element={<AdminAddProjectPage />} />
          <Route path="/user/allorders" element={<UserAllOrdersPage />} />
          <Route path="/user/favoriteproducts" element={<UserFavoriteProductsPage />} />
          <Route path="/user/addresses" element={<UserAllAddresPage />} />
          <Route path="/user/add-address" element={<UserAddAddressPage />} />
          <Route path="/user/edit-address" element={<UserEditAddressPage />} />  
          <Route path="/user/profile" element={<UserProfilePage />} /> 
          {/* <Route path="/admin/editProduct/:id" element={<AdminEditProductsPage />} />  */}

   </Routes>
      </BrowserRouter>
   </Container>
<Footer/>
    </>
         
  );
}

export default App;
