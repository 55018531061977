import React from 'react'
import { Form,Row,Col,Container ,input, Spinner } from 'react-bootstrap'
import EditProjectHook from '../../Custom_Hook/Project/EditProjectHook';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const AdminEditProject = () => {
    const {id} = useParams()

    const [img,name,projectdate,destname,location,projectdesc,loading,isPress,onImageChange,onChangeName,onChangeDestName,onChangeLocation,onChangeProjectdate,onChangeprojectdesc,UpdateProject] = EditProjectHook(id);  


  return (
    <div>
      <Row className="justify-content-start ">
                <div className="admin-content-text pb-4">  تعديل مشروع جديد</div>
                <Col sm="8">
                    <div className="text-form pb-2"> صور للمشروع</div>
                    {/* <img src={avatar} alt="" height="100px" width="120px" /> */}

                    <label for="upload-photo">
                        <img src={img} alt="" height="100px" width="120px" style={{cursor:"pointer"}}/>
                        <input type='file' name="photo" onChange={onImageChange} id="upload-photo"/>

                        {/* <img  alt="" height="100px" width="120px" style={{cursor:"pointer"}}/>
                        <input type='file' name="photo"  id="upload-photo"/> */}
                        </label>
                    <input
                      onChange={onChangeName}
                      value={name}
                        type="text"
                        className="input-form d-block mt-3 px-3"
                        placeholder="اسم المشروع"
                    />
                    <textarea
                      onChange={onChangeprojectdesc}
                      value={projectdesc}
                        className="input-form-area p-2 mt-3"
                        rows="4"
                        cols="50"
                        placeholder="وصف المشروع"
                    />
                    <input
                     onChange={onChangeLocation}
                     value={location}
                        type="text"
                        className="input-form d-block mt-3 px-3"
                        placeholder="موقع المشروع"
                    />
                    <input
                     onChange={onChangeDestName}
                     value={destname}
                        type="text"
                        className="input-form d-block mt-3 px-3"
                        placeholder=" الجهة المالكة"
                    />
                
                <input
                 onChange={onChangeProjectdate}
                 value={projectdate}
                        type="text"
                        className="input-form d-block mt-3 px-3"
                        placeholder="تاريخ  بداية المشروع"
                    />
                    
                    {/* <input
                 value={id}
                        type="hidden"
                    /> */}

      {/* <input
  onChange={(e) =>setCreated_at(e.target.value)}
  value={created_at}
                        type="date"
                        className="input-form d-block mt-3 px-3"
                        placeholder=" تاريخ نهاية المشروع"
                    /> */}
               
                 
                
                </Col>
            </Row>
            <Row>
                <Col sm="8" className="d-flex justify-content-end ">
                    <button onClick={UpdateProject}  className="btn-save d-inline mt-2 ">حفظ التعديلات</button>
                </Col>
              
                {
                    isPress? loading?
                    <h4>  <Spinner animation="border" variant="success"  size="lg"/>  </h4>
                  :<h4>تم الانتهاء</h4>:null
                }
      
            </Row>

            <ToastContainer />
    </div>
  )
}

export default AdminEditProject
