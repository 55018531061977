import React from 'react'
import { GET_ALL_Images,GET_ALL_Images2,GET_ALL_Images3,GET_ERROR,CREATE_GALLERY} from '../Type'
import UseGetData from '../../Hooks/UseGetData';
import { UseInsertData } from '../../Hooks/UseInsertData';



    //action display all Gallery 
export const getAllGallery =()=> async (dispatch)=>{
    try{
        // const res = await BaseURL.get('/public/api/projects/all');  
        const response =await UseGetData('/public/api/gallary/show/1');
        // console.log(res.data)
        dispatch({
            type:GET_ALL_Images,
            payload : response,
            loading:true,
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
        } )  
    }
}




    //action display all Gallery 
    export const getAllGalleryT2 =()=> async (dispatch)=>{
        try{
            // const res = await BaseURL.get('/public/api/projects/all');  
            const response =await UseGetData('/public/api/gallary/show/2');
            // console.log(res.data)
            dispatch({
                type:GET_ALL_Images2,
                payload : response,
                loading:true,
            } )                                                   
        }catch(e){
            dispatch({
                type:GET_ERROR,
                payload :"Error"+e,
            } )  
        }
    }


    export const getAllGalleryT3 =()=> async (dispatch)=>{
        try{
            // const res = await BaseURL.get('/public/api/projects/all');  
            const response =await UseGetData('/public/api/gallary/show/3');
            // console.log(res.data)
            dispatch({
                type:GET_ALL_Images3,
                payload : response,
                loading:true,
            } )                                                   
        }catch(e){
            dispatch({
                type:GET_ERROR,
                payload :"Error"+e,
            } )  
        }
    }


//action CreateGallery
export const CreateGallery =(Data)=> async (dispatch)=>{
    try{
        const response =await UseInsertData('/public/api/gallary/create',Data);
        console.log(response)
        dispatch({
            type:CREATE_GALLERY,
            payload:response,
            loading:true
        } )                                                   
    }catch(e){
        dispatch({
            type:GET_ERROR,
            payload :"Error"+e,
          
        } )  
        console.log(e)
    }
}



