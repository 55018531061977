import React from 'react'
// import client from '../assets/img/clients/client.png';
import {  Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const OurClients = () => {
  return (
    <section id="clients" NameName="clients">
      <div className="container">
        <div className="section-title">
          <h2>العملاء</h2>
          <p>
            حصل على العديد من الاعتمادات العالمية وذلك يعود لإهتمامها الدائم بجميع معايير الجودة وتقديم أفضل تجربة ممكنة لعملائها الكرام
          </p>
        </div>

        <Swiper
      
       spaceBetween={10}


       breakpoints={{
        390: {
          slidesPerView: 4,
          spaceBetween: 20,
        },

        640: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 50,
        },
      }}

        pagination={{
          clickable: true,
        }}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
    
        modules={[Autoplay, Pagination, Navigation]}
        className="clients-slider swiper"
      >
        <div className="swiper-wrapper align-items-center">
        <SwiperSlide><img src="/clients/1.png"  className="img-fluid" alt="" /> </SwiperSlide>
        <SwiperSlide><img src="/clients/2.jpg"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/3.jpg"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/4.jpg"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/5.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/6.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/7.jpg"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/8.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/9.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/10.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/11.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/12.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/13.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/14.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/15.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/16.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/17.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/18.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/19.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/20.png"  className="img-fluid" alt="" /></SwiperSlide>
        <SwiperSlide><img src="/clients/21.png"  className="img-fluid" alt="" /></SwiperSlide>
        </div>
      </Swiper>
   



        {/* <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
            <div className="swiper-slide">  <img src={client.png} className="img-fluid" alt="" /> </div>
            <div className="swiper-slide">  <img src={client.png} className="img-fluid" alt="" /> </div>
            <div className="swiper-slide">  <img src={client.png} className="img-fluid" alt="" /> </div>
            <div className="swiper-slide">  <img src={client.png} className="img-fluid" alt="" /> </div>
          </div>
          <div className="swiper-pagination"></div>
        </div> */}

      </div>
    </section>
  )
}

export default OurClients





